import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import HeroSection from '../components/HeroSection';
import { Heading1, Paragraph } from '../components/Typography';
import Link from '../components/Link';
import covid19ReturnToWorkAssessment from '../files/Jacobs-COVID19-Risk-Assessment-v1.3-04.01.21-2.pdf';

const Covid19 = () => {
  return (
    <>
      <SEO
        title='COVID-19 Information'
        description={`
        Our specialist trained teams are available to discuss your case, take
        payments and review existing and make new arrangements to pay.
        `}
      />
      <HeroSection>
        <Heading1 paddingTop='120px'>COVID-19 Information</Heading1>
        <Paragraph maxWidth='680px'>
          Our specialist trained teams are available to discuss your case, take
          payments and review existing and make new arrangements to pay.
        </Paragraph>
      </HeroSection>
      <Container>
        <div style={{ maxWidth: '680px' }}>
          <Paragraph marginTop='40px'>
            We are also able to provide you with information on where to obtain
            additional support including free welfare advice and information on
            financial support that may be available through Government & Local
            Council schemes.
          </Paragraph>
          <Paragraph marginBottom='200px'>
            Read our{' '}
            <Link href={covid19ReturnToWorkAssessment}>
              comprehensive risk assessment
            </Link>
            . We have also implemented the CIVEA post lockdown support plan. For
            more information,{' '}
            <Link href='/news/2020-08-31_July'>please read our article</Link>.
          </Paragraph>
        </div>
      </Container>
    </>
  );
};

export default Covid19;
